/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
// ProductListPage.component.tsx
import React from 'react';

import ProductCard from 'Component/ProductCard';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductListPageComponent as SourceProductListPageComponent,
} from 'SourceComponent/ProductListPage/ProductListPage.component';
import { ReactElement } from 'Type/Common.type';
import { PRODUCTS_PRELOAD_COUNT } from 'Util/Product';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { AfterPriority } from 'Util/Request/LowPriorityRender';

import './ProductListPage.style.scss';

/** @namespace Inov8/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPageComponent {
    renderPageItems(): ReactElement {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
        } = this.props;

        return items.map((product, i) => {
            if (i < PRODUCTS_PRELOAD_COUNT) {
                return (
                    <ProductCard
                      product={ product }
                    // eslint-disable-next-line react/no-array-index-key
                      key={ i }
                      selectedFilters={ selectedFilters }
                      layout={ layout as CategoryPageLayout }
                      { ...this.containerProps() }
                      onLoad={ setLoadedFlag }
                    />
                );
            }

            return (
                // @ts-ignore
                <AfterPriority fallback={ <div style={ { minHeight: 200 } } /> }>
                    <ProductCard
                      product={ product }
                    // eslint-disable-next-line react/no-array-index-key
                      key={ i }
                      selectedFilters={ selectedFilters }
                      layout={ layout as CategoryPageLayout }
                      { ...this.containerProps() }
                      onLoad={ setLoadedFlag }
                    />
                </AfterPriority>
            );
        });
    }
}

export default ProductListPageComponent;
